
import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { Service } from "src/decorator";
import { RightsService } from "src/services";
import dayjs from "dayjs";
import globalConfig from "src/config/index";

@Component
export default class RightsInformation extends Vue
{

    /**
     * 卡片信息
     * @private
     * @returns any
     */
    private cardInfo: any = {
        orderId: 0,
        rightsMemberName: "",
        memberId: 0,
        memberName: "",
        phone: "",
        cardNo: "",
        rightsId: 0,
        rightsName: "",
        backgroundColor: "",
        rightsDesc: "",
        activedLimitTime: "",
        activedLimitTimeStr: "",
        activedEffTime: "",
        activedEffTimeStr: ""
    };

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 获取订单列表
     * @private
     * @returns void
     */
    private async getCard(): Promise<void>
    {
        try
        {
            let { content: result } = await RightsService.instance.clientGetCard(this.rightsId);
            if(result.data)
            {
                this.cardInfo = result.data;
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 权益卡id
     * @private
     * @returns number
     */
    private get rightsId(): any
    {
        return this.$route.query.id + '';
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        this.getCard();
    }

}
