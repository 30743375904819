
import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { Service } from "src/decorator";
import { RightsService } from "src/services";
import dayjs from "dayjs";
import globalConfig from "src/config/index";

@Component
export default class RightsDetail extends Vue
{


    /**
     * 是否显示激活框
     * @private
     * @returns string
     */
    private showActivate: boolean = false;

    /**
     * 权益数据
     * @private
     * @returns string
     */
    private rightsData: any = {
        id: 0,
        status: 0,
        statusStr: "",
        isOvertimeUnPay: true,
        payDeadlineTime: "",
        payDeadlineMilliseconds: 0,
        sourceKind: 2,
        sourceKindStr: "",
        orderNo: "",
        price: 0,
        enterpriseAmout: 0,
        marketPrice: 0,
        actualAmount: 0,
        createdTime: "",
        cancelTime: "",
        payedTime: "",
        refundedTime: "",
        completedTime: "",
        backgroundColor: "",
        activedLimitTime: "",
        activedLimitTimeStr: "",
        activedEffTime: "",
        activedEffTimeStr: "",
        rightsId: 0,
        rightsName: "",
        rightsDesc: "",
        rightsProducts: [],
        rightsUsedRecords: []
    };

    /**
     * 是否显示使用记录
     * @private
     * @returns string
     */
    private showHis: boolean = false;

    /**
     * 加载
     */
    private loading:boolean = false

    /**
     * 时间处理工具
     * @private
     * @returns dayjs
     */
    private dayjs: any = dayjs;

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 权益卡id
     * @private
     * @returns number
     */
    private get rightsId(): any
    {
        return this.$route.query.id + '';
    }

    /**
     * 获取订单列表
     * @private
     * @returns void
     */
    private async getRightsDetail(): Promise<void>
    {
        try
        {
            let { content: result } = await RightsService.instance.getClient(this.rightsId);
            if(result.data)
            {
                this.rightsData = result.data;
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        this.getRightsDetail();
    }

    /**
     * 跳转页面
     * @private
     * @returns void
     */
    private onGopage(name: string, id: any): void
    {
        if (id)
        {
            this.$router.push({name, query: {id: id+""}});
        }
        else
        {
            this.$router.push({name});
        }
    }

    /**
     * 显示使用记录
     * @private
     * @returns void
     */
    private onShowHis(): void
    {
        this.showHis = true;
    }

    /**
     * 去使用
     * @private
     * @returns void
     */
    private onToUsing(item: any): void
    {
        if (!item.canUse)
        {
            return;
        }
        if (item.orderKind == 4) // 实物
        {
            this.$router.push({name: 'goodsDetail', query:{id: item.id, rightsId: item.memberRightsId}})

        }
        else if (item.orderKind == 6) // 医疗服务
        {
            this.$router.push({name: 'medical', query:{id: item.id, rightsId: item.memberRightsId}})
        }
    }

    /**
     * 激活
     * @private
     * @returns void
     */
    private async onActive(): Promise<void>
    {
        try
        {
            await RightsService.instance.clientActive(this.rightsData.id);
            Toast("激活成功");
            this.showActivate = false;
            this.getRightsDetail();
        }
        catch(err)
        {
            Toast(err);
        }
    }
    
}
